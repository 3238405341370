<template>
    <div>
        <div class="main-title">图片列表</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head" >
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">文件格式支持：png,jpg</li>
                        <li data-v-69874ad0="">文件大小不能超过20M</li>
                    </ul>
                </div>
            </div>


            <div class="padding-t-sm padding-b-sm">

<!--                <span class="fl-l" >-->
<!--                    <el-input-->
<!--                        size="mini"-->
<!--                        placeholder="文件名称"-->
<!--                        v-model="fileName"-->
<!--                        clearable>-->
<!--                    </el-input>-->
<!--                </span>-->


<!--                <span class="margin-l-sm fl-l">-->
<!--                    <el-button type="primary" icon="el-icon-search" size="mini" @click="search">搜索</el-button>-->
<!--                </span>-->

                <span class=" fl-r">
                    <router-link to="/images/add" class="router-link" >
                        <el-button type="primary" size="mini" >上传图片</el-button>
                    </router-link>
                </span>
                <div style="clear: both"></div>
            </div>


            <div class="table-content margin-t-sm"  v-loading="loading">

                <div class="image-list" >

                    <div class="image-item" v-for="(file,index) in files" :key="index">
                        <div class="image-item-top">
                            <el-image style="width: 275px;height: 157px" :src=" imageUrl + file.path"/>
                        </div>
                        <div class="image-option">
                            <el-popconfirm  class="item" effect="dark" content="删除" placement="top"
                                    title="删除后将不可恢复，是否删除？"
                                            @confirm="deleted(file)"
                            >
                                <i slot="reference" class="el-icon-delete" ></i>
                            </el-popconfirm>
                        </div>
                    </div>
                </div>

            </div>

            <div class="table-batch" >
                <span class="fl-r" >
                    <el-pagination
                        small
                        background
                        :current-page="page"
                        :page-sizes="[9, 18]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="setPageSize"
                        @current-change="setPage"
                        :total="total">
                </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import {config} from "../../../js/config";

    export default {
        name: "list",
        data(){
            return {
                loading: true,
                visible: true,
                files:[],
                page:1,
                fileName: '',
                pageSize:9,
                total:0,
                imageUrl:config.imageUrl,
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }],
                value: '',
                value2: '',
                tableData: [],
                currentRow: null
            }
        },
        methods:{
            ...mapActions('images',['getImageList']),
            ...mapActions('common',['delete']),
            search(){
                this.tableData = []
                this.page = 1
                this.getList()
            },
            getList(){
                this.loading = true
                let data = {
                    page:this.page,
                    pageSize:this.pageSize,
                }
                if(this.fileName!= '')
                {
                    data.name = this.fileName
                }
                this.getImageList(data).then(res => {
                    console.log(res)
                    this.loading = false
                    let data = [];
                    res.data.list.forEach(item => {
                        data.push({
                            id:item.id,
                            name:item.name,
                            path:item.path,
                            size:item.size,
                            status:item.status,
                            suffix:item.suffix,
                            user_id:item.user_id,
                            setName:false,
                            btnLoad:false,
                        })
                    })
                    this.total = res.data.total
                    this.files = data
                    this.loading = false
                })
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
            showSetName(file){
                file.setName = true
            },
            deleted(row){
                this.delete({
                    model:'Images',
                    id:row.id
                }).then(res => {
                    if(res.ret === 0){
                        this.$message.success('删除成功')
                        this.getList()
                    }else{
                        this.$message.error('删除失败')
                    }
                })
            },
            hideSetName(file)
            {
                file.setName = false
            },
            setName(file){
                let data = {
                    id:file.id,
                    name:file.name,
                }
                file.btnLoad = true
                this.editResourceName(data).then(res => {
                    if(res.ret == 0){
                        this.$message.success('修改成功！');
                    }else{
                        this.$message.error(res.res_info);
                    }
                    this.hideSetName(file)
                }).finally(()=>{
                    file.btnLoad = false
                })
            }
        },
        mounted() {
            this.getList()
        }
    }
</script>

<style>
.image-list{
    display: flex;
    flex-wrap:wrap;
}
.el-card__body{
    padding:10px
}
.image-item{
    margin-right: 10px;
    margin-bottom: 10px;
    width: 32%;
    border:1px solid #ebebeb;
}
.image-icon{
    display: block;
    width: 100%;
    height: 100%;
}
.image-info{
    float: left;
    margin-left:10px
}
.image-item-top{
    padding: 10px;
    width: 100%;
    height: calc(100% - 36px);
}
.image-info-title{
    color: #333;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 20px;
    width: 180px;
    overflow: hidden;
}
.image-info-size{
    color: #999;
    font-size: 12px;
}
.image-option{
    height: 36px;
    line-height: 36px;
    padding:0 10px;
    background: #fafafa;
    border-top:1px solid #ebebeb;


}
.image-option .item{
    color: #aaaaaa;
    cursor: pointer;
    margin-right: 10px;
}
.clear{
    clear: both;
}
    .set-name{
        padding-bottom: 10px;
    }
</style>
